import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 100px;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 500px;
  margin-left: auto;
  margin-right: auto;
  h1,
  h2,
  h3 {
    color: white;
  }
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <h1>404. Page not found.</h1>
      <h3>Sorry, the page you're looking for was not found. :(</h3>
    </Container>
  </Layout>
)

export default NotFoundPage
